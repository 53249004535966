<template>
    <transition name="fade">
        <div class="age-gate" v-if="$store.state.ageGateModalActive">
            <div class="age-gate__bg"></div>

            <img src="../assets/global/icon-close2-black.svg" alt = "Close button icon" class="modal__btn modal__btn--close" @click="closeModal">

            <div class="content__wrap">

                <div class="content__wrap__caption">

                    <div class="content__wrap__caption__logo">
                        <img src="../assets/global/logo-bug.svg" alt="Santa Monica Studios Logo - No Text">
                    </div>

                    <div v-if="!$store.state.ageGateLockedOut">
                        <div class="content__wrap__caption__please">
                            PLEASE ENTER YOUR DATE OF BIRTH
                        </div>
                        <!-- <div class="content__wrap__caption__once">
                            ( YOU ONLY NEED TO ENTER ONCE )
                        </div> -->
                    </div>

                    <div class="content__wrap__caption__prohibited" v-if="$store.state.ageGateLockedOut">
                        You are not authorized to view this content

                        <!--<button class="btn btn--red-outline" type="submit">
                                    <span>RETURN HOME</span>
                                </button>-->

                    </div>

                </div>

                <form @submit.prevent="handleSubmit" class="content__wrap__form" v-if="!$store.state.ageGateLockedOut">

                    <div class="fields-block">
                        <div class="date-field">
                            <input v-on:keyup="onKeyUp" @keypress="isNumber($event)" class="inputs" id="mnth" name="month" placeholder="MM" value="MM" min="1" max="12" maxlength="2" autofocus="autofocus" required="" tabindex="1" v-model="month" ref="month">
                        </div>

                        <div class="date-field">
                            <input v-on:keyup="onKeyUp" @keypress="isNumber($event)" class="inputs" id="dy" name="day" placeholder="DD" value="DD" min="1" max="31" maxlength="2" required="" tabindex="2" v-model="day" ref="day">
                        </div>

                        <div class="date-field">
                            <input v-on:keyup="onKeyUp" @keypress="isNumber($event)" class="inputs" id="yr" name="year" placeholder="YYYY" value="YYYY" min="1900" max="2100" maxlength="4" required="" tabindex="3" v-model="year" ref="year">
                        </div>

                    </div>

                    <div class="submit-block">
                        <button class="btn btn--red-outline" type="submit">
                            <span>SUBMIT !</span>
                        </button>
                    </div>

                </form>

            </div>
        </div>
    </transition>
</template>

<script>
    import Vue from 'vue'
    import router from './../router'
    import axios from 'axios'

    export default {
        props: ['data'],
        data() {
            return {
                day: '',
                month: '',
                year: ''
            }
        },
        mounted() {
            // this.init();
        },
        methods: {
            init() {
                this.addKeyListeners();
            },
            addKeyListeners() {
                document.addEventListener("keyup", this.onKeyUp);
            },

            onKeyUp(e) {

                var $input = $(e.currentTarget);
                var maxLength = $input.attr('maxlength');
                var inputName = $input.attr('name');

                if ($input.val().length == maxLength) {
                    if (inputName == 'month') $(this.$refs.day).focus();
                    if (inputName == 'day') $(this.$refs.year).focus();
                }

            },
            isNumber: function(evt) {
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                    evt.preventDefault();;
                } else {
                    return true;
                }
            },
            handleSubmit() {
                axios.get('/data/ageGate/verify.json?d=' + this.month + '-' + this.day + '-' + this.year)
                    .then(response => {
                        // console.log(response.data.ageGate);
                        this.$store.state.ageGateLockedOut = response.data.ageGate.lockedOut;

                        // hide modal if age is old enough and not locked out
                        if (response.data.ageGate.ageVerified && !response.data.ageGate.lockedOut) {
                            this.$store.state.ageGateModalActive = false;
                            // $('body').removeClass('age-gate-active');
                            // $('html').removeClass('age-gate-active');

                            // safari is jumping to bottom of page for some reason
                            // setTimeout(()=> { $(window).scrollTop(0); }, 600);
                            // setTimeout(()=> { $(window).scrollTop(0); }, 1000);
                        }

                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            closeModal() {
                router.push(this.$route.fullPath.indexOf('stories') > -1 ? '/stories' : '/');
                setTimeout(() => {
                    this.$store.state.ageGateModalActive = false;
                }, 500);
            },
        }
    }
</script>