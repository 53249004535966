import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        preloadingSection: true,
        preloadStartTime: new Date().getTime(),
        preloadMinStallTime: 300,
        ageGateModalActive: false,
        ageGateLockedOut: false,
    },
    mutations: {
        SET_SECTION_PRELOAD_STATUS(state, preloading) {
            if (preloading == false) {
                state.preloadingSection = false;
                $('body').removeClass('no-scroll');
                $('html').removeClass('no-scroll');
                state.preloadMinStallTime = 1150;   // stall is 0 on site load, then change to 1.1 secs
            } else {
                state.preloadingSection = true;
            }
        },
    },
    getters: {
        storiesSubPage (state) {
            return state.storiesSubPage
        }
    },
    actions: {
        startSectionPreload(context) {
            context.state.preloadStartTime = new Date().getTime();
            context.commit('SET_SECTION_PRELOAD_STATUS', true);
        },
        endSectionPreload(context) {
            var preloadEndTime = new Date().getTime();
            var loadTime = preloadEndTime - context.state.preloadStartTime;
            if (loadTime < context.state.preloadMinStallTime) {
                var timeoutDuration = context.state.preloadMinStallTime - loadTime;
                setTimeout(() => {
                    context.commit('SET_SECTION_PRELOAD_STATUS', false);
                }, timeoutDuration);
            } else {
                context.commit('SET_SECTION_PRELOAD_STATUS', false);
            }
        }
    }
})