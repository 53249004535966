import Vue from 'vue'
import $ from 'jquery'
import router from './router'
import gsap, { Power2} from 'gsap'
import ScrollToPlugin from "gsap/ScrollToPlugin"
gsap.registerPlugin(ScrollToPlugin)

Vue.prototype.$body = $('body');
Vue.prototype.$html = $('html');
Vue.prototype.$window = $(window);
Vue.prototype.$document = $(document);
// Vue.prototype.$pageTitleTemplate = 'Stanta Monica Studio - %s';

Vue.prototype.$sectionLoaded = function () {

    // $('body').removeClass('is-home');
    this.$store.dispatch('endSectionPreload');

    // make sure main logo is full size and reset for each section
    // (homepage shrinks it down)
    $('#header-nav').removeClass('shrink-logo');
    $('.header__logo__text').css({
        opacity: ''
    });
    // $('.header__logo a').css({ transform: '' });
    gsap.set($('.header__logo a'), {
        clearProps: 'all'
    });

}

Vue.prototype.$scrollTo = function (target, offset) {
    gsap.to(window, 0.8, {
        scrollTo: {
            y: target,
            offsetY: offset ? offset : 0
        },
        ease: Power2.easeInOut
    });
}

Vue.prototype.$json = function (defaultPath) {
    let preview = new URLSearchParams(window.location.search).get('preview');
    return preview ? '/data/preview/' + preview + '.json' : defaultPath;
}

Vue.prototype.$checkAgeGate = function (data) {

    // console.log(data);
    
    this.$store.state.ageGateLockedOut = data.lockedOut;

    // show age gate "prohibited" screen if locked out
    if (data.lockedOut && data.ageRestricted) {
        this.$store.state.ageGateModalActive = true;
        return;
    }

    // show age gate modal if page is age-restricted and user's age hasn't been verified
    if (data.ageRestricted && !data.ageVerified) {
        this.$store.state.ageGateModalActive = true;
        // $('body').addClass('age-gate-active');
        // $('html').addClass('age-gate-active');
        return;
    }

    // if (data.ageVerified && !data.lockedOut) {
    //     $('body').removeClass('age-gate-active');
    //     $('html').removeClass('age-gate-active');
    // }

}





