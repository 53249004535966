<template>
    <div id="app">
        <header-nav></header-nav>
        <router-view />
        <section-transition />
        <age-gate />
        <div class="blocker">
            <img src="/img/home/blocker.svg" alt = "">
        </div>
    </div>
</template>

<script>
    import HeaderNav from '@/components/HeaderNav'
    import SectionTransition from '@/components/SectionTransition'
    import AgeGate from '@/modals/AgeGate'

    export default {
        components: {
            HeaderNav,
            SectionTransition,
            AgeGate
        }
    }
</script>

<style lang="scss">
    @import './sass/app.scss';
</style>

