import 'url-search-params-polyfill';
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import cssua from 'cssuseragent';

import Vue2TouchEvents from 'vue2-touch-events'
import $ from 'jquery'
import Buefy from 'buefy'
import './vue-prototypes.js'
import Lethargy from './vendor/Lethargy'
import VueScrollReveal from 'vue-scroll-reveal';
import imagePreloader from 'vue-image-preloader'
import VueAnalytics from 'vue-analytics'
import VueMeta from 'vue-meta'

window.$ = $

Vue.config.productionTip = false;
Vue.use(Buefy);
Vue.use(Vue2TouchEvents);
Vue.use(imagePreloader);
Vue.use(VueScrollReveal, {
  duration: 1000,
  distance: '150px',
  delay: 0
});
Vue.use(VueAnalytics, {
  id: 'UA-45288163-1',
  router,
  debug: {
      enabled: false, // print to console
      sendHitTask: process.env.NODE_ENV == 'production' // only track in production
  }
})
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
