<template>
    <header id="header-nav" ref="header" v-if="data">
        <div class="header lockout">
            <div class="header__logo">
                <router-link to="/">
                    <img src="../assets/global/logo-bug.svg" alt="Santa Monica Studios Logo - Only Color Swatch" class="header__logo__bug">
                    <img src="../assets/global/logo-text.svg" alt="Santa Monica Studios Logo - Only Text" class="header__logo__text">
                    <img src="../assets/global/logo-mobile.svg" alt="Santa Monica Studios - Text & Color Swatch" class="header__logo__mobile">
                </router-link>
            </div>
            <nav class="header__nav">
                <ul>
                    <li>
                        <router-link to="/who-we-are">Who We Are</router-link>
                    </li>
                    <li>
                        <router-link to="/studio-life">Studio Life</router-link>
                    </li>
                    <li>
                        <router-link to="/careers">Careers</router-link>
                    </li>
                    <li>
                        <router-link to="/stories">Stories</router-link>
                    </li>
                    <li>
                        <router-link to="/fandom">Fandom</router-link>
                    </li>
                    <li>
                        <router-link to="/connect">Connect</router-link>
                    </li>
                    <li>
                        <a href="https://support.sms.playstation.com/hc/" target="_blank">Support</a>
                    </li>
                </ul>
            </nav>
            <div class="header__hamburger" @click="mobileOpen = !mobileOpen">
                <img src="../assets/global/icon-hamburger.svg" v-if="!mobileOpen" alt = "Mobile Menu Hamburger open icon">
                <img src="../assets/global/icon-close.svg" v-if="mobileOpen" alt = "Mobile Menu Hamburger close icon" class="header__hamburger__close">
            </div>
        </div>
        <div id="mobile-menu" :class="{'mobileOpen': mobileOpen}" ref="mobileMenu">
            <ul class="mobile__inner">
                <li>
                    <router-link to="/who-we-are">Who We Are</router-link>
                </li>
                <li>
                    <router-link to="/studio-life">Studio Life</router-link>
                </li>
                <li>
                    <router-link to="/careers">Careers</router-link>
                </li>
                <li>
                    <router-link to="/stories">Stories</router-link>
                </li>
                <li>
                    <router-link to="/fandom">Fandom</router-link>
                </li>
                <li>
                    <router-link to="/connect">Connect</router-link>
                </li>
                <li>
                    <a href="https://support.sms.playstation.com/hc/" target="_blank">Support</a>
                </li>
            </ul>

            <ul class="mobile__right">
                <div class="tooltip">
                    <div class="diamonds">
                        <div class="diamond"></div>
                        <div class="diamond"></div>
                        <div class="diamond"></div>
                    </div>
                    <p>FOLLOW US</p>
                </div>
                <a class="circle tw" :href="data.links.tw" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90.43 73.32">
                        <path class="cls-1" d="M81.23,18.17v2.35c0,24.59-18.59,52.8-52.8,52.8A51.63,51.63,0,0,1,0,65a32.14,32.14,0,0,0,4.49.21,37.69,37.69,0,0,0,23.09-7.91A18.7,18.7,0,0,1,10.26,44.46a18.18,18.18,0,0,0,3.42.43,18.17,18.17,0,0,0,4.92-.64,18.57,18.57,0,0,1-15-18.17v-.21A19,19,0,0,0,12,28.22,18.84,18.84,0,0,1,3.63,12.83,17.78,17.78,0,0,1,6.2,3.42,52.8,52.8,0,0,0,44.46,22.87,26.75,26.75,0,0,1,44,18.6,18.6,18.6,0,0,1,76.1,5.77,37,37,0,0,0,87.86,1.28a19.34,19.34,0,0,1-8.12,10.26,35.75,35.75,0,0,0,10.69-3A35.78,35.78,0,0,1,81.23,18.17Z" /></svg>
                </a>
                <a class="circle fb" :href="data.links.fb" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43.43 93.32">
                        <path class="cls-1" d="M41.81,46.66H28.64V93.32H9.24V46.66H0V30.26H9.24V19.63C9.24,12,12.94,0,28.87,0H43.2V15.94H32.8a4,4,0,0,0-4.16,4.39V30H43.43Z" /></svg>
                </a>
                <a class="circle ig" :href="data.links.ig" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85.98 86">
                        <path class="cls-1" d="M43,0c6.18,0,12.36,0,18.53,0A39.65,39.65,0,0,1,67,.56a16.6,16.6,0,0,1,2.7.79c.65.21,1.29.42,1.92.67a13.69,13.69,0,0,1,1.34.64c.56.3,1.13.59,1.66.94a25.74,25.74,0,0,1,2.27,1.6A28,28,0,0,1,79,7.13a18,18,0,0,1,1.42,1.6c.65.83,1.32,1.66,1.89,2.55a25.54,25.54,0,0,1,1.24,2.38c.26.53.5,1.07.76,1.6,0,.06.06.1.08.16.22.78.4,1.57.68,2.33a19.76,19.76,0,0,1,.7,4.17,17.66,17.66,0,0,1,.2,2.4q0,18.95,0,37.89a35.51,35.51,0,0,1-.41,3.95,14,14,0,0,1-.89,3.44c-.24.64-.43,1.29-.68,1.92s-.46,1-.71,1.49-.52,1-.82,1.49a13.93,13.93,0,0,1-1.13,1.7c-.82,1-1.66,2-2.58,2.9A18.66,18.66,0,0,1,75.07,82c-1.1.63-2.2,1.28-3.33,1.86a11.91,11.91,0,0,1-1.79.65c-.88.28-1.76.59-2.66.81-.67.16-1.39.16-2.07.3A32.89,32.89,0,0,1,58.5,86H27.88a40,40,0,0,1-8.11-.44c-.51-.1-1-.22-1.53-.34-.25,0-.51-.1-.77-.17s-.68-.21-1-.33c-.11,0-.21-.09-.33-.12a14.82,14.82,0,0,1-3.37-1.46,28.1,28.1,0,0,1-2.89-1.75,33,33,0,0,1-3.14-2.76,17.87,17.87,0,0,1-2-2.38C4,75.35,3.5,74.38,3,73.43a17.35,17.35,0,0,1-.81-1.54c-.26-.58-.48-1.18-.68-1.78-.3-.92-.62-1.84-.85-2.78-.16-.64-.14-1.33-.28-2A28.13,28.13,0,0,1,0,59.41q0-18,0-36.09A22.36,22.36,0,0,1,.24,21c.06-.49.11-1,.2-1.47s.2-.88.3-1.32c0-.12.09-.24.12-.37.22-.8.4-1.6.65-2.39a7.94,7.94,0,0,1,.58-1.3,32.16,32.16,0,0,1,1.5-2.85c.66-1,1.44-2,2.19-2.91A18.26,18.26,0,0,1,9.43,5c.75-.55,1.54-1.06,2.34-1.55s1.56-.9,2.36-1.29A13.34,13.34,0,0,1,16,1.45c.87-.28,1.75-.52,2.63-.78A1.57,1.57,0,0,1,19,.54C20.71.42,22.39,0,24.13,0,30.42,0,36.71,0,43,0ZM19.07,42.59A23.91,23.91,0,1,0,43,19.09,23.85,23.85,0,0,0,19.07,42.59Zm57.29-28.2a3.75,3.75,0,0,0-1.09-3.11,3.78,3.78,0,0,0-2.36-1.54,5.37,5.37,0,0,0-3.45.35,4.88,4.88,0,0,0-2.63,3.67,4.63,4.63,0,0,0,1.55,4.11,4.68,4.68,0,0,0,6.77-.24,3.81,3.81,0,0,0,1.21-2.89C76.35,14.62,76.36,14.51,76.36,14.39Z" />
                        <path class="cls-1" d="M43,28.75A14.26,14.26,0,1,1,28.72,42.5,14.12,14.12,0,0,1,43,28.75Z" /></svg>
                </a>
                <a class="circle yt" :href="data.links.yt" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.23 28.01">
                        <path class="cls-1" d="M0,17.28V10.73c0-.11,0-.21.05-.32C.1,9.61.13,8.81.19,8a16.25,16.25,0,0,1,.72-4.1A4.77,4.77,0,0,1,4.62.64,14.73,14.73,0,0,1,6.92.37C9.61.23,12.3.06,15,0c3.6,0,7.19,0,10.79,0,3,0,6.07.17,9.1.47a5.05,5.05,0,0,1,4.6,3.89,39.89,39.89,0,0,1,.6,4.09c.09.72.1,1.45.14,2.17v6.62c0,.13,0,.25,0,.38,0,.81-.07,1.62-.14,2.43a15.8,15.8,0,0,1-.72,4,4.83,4.83,0,0,1-3.71,3.24,12.51,12.51,0,0,1-2,.25c-2.78.14-5.56.32-8.34.36-3.6.06-7.19,0-10.79,0-3,0-6.08-.17-9.1-.47A5.1,5.1,0,0,1,.68,23.33C.43,22,.29,20.56.12,19.17.05,18.54,0,17.91,0,17.28Zm27.72-3.66L14.94,7v13.3Z" /></svg>
                </a>
                <a class="circle li" :href="data.links.li" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 83.19 83.19">
                        <path class="cls-1" d="M76,83.19H7.15A2.28,2.28,0,0,0,6.79,83a8.48,8.48,0,0,1-4.9-2.94A10.43,10.43,0,0,1,0,76V7.15a1,1,0,0,0,.14-.28A8.35,8.35,0,0,1,8.58,0h66a9.48,9.48,0,0,1,2.25.26,8.37,8.37,0,0,1,6.32,8.39V74.53c0,.25,0,.49,0,.73a8.37,8.37,0,0,1-2.83,5.83A10,10,0,0,1,76,83.19ZM70.67,70.67c0-.24,0-.4,0-.56,0-7.69,0-15.38,0-23.07A14,14,0,0,0,65.48,36c-4.45-3.77-10.71-5-16.25-1.12a39.22,39.22,0,0,0-3.45,3.05V33.36H33.36v37.3H45.77v-1q0-10.06,0-20.14a14.53,14.53,0,0,1,.07-1.62,6.26,6.26,0,0,1,7.81-5.19,6.34,6.34,0,0,1,4.57,6.44q0,10.27,0,20.55v1ZM12.55,33.32V70.64H24.91V33.32Zm13.63-14.6a7.48,7.48,0,1,0-7.49,7.46A7.5,7.5,0,0,0,26.18,18.72Z" /></svg>
                </a>

                <div class="tooltip email">
                    <div class="diamonds">
                        <div class="diamond"></div>
                        <div class="diamond"></div>
                        <div class="diamond"></div>
                    </div>
                    <p>EMAIL US</p>
                </div>

                <div class="circles">
                    <a class="circle email" :href="data.links.email">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.02 28.01"><path class="cls-1" d="M38,28H0V0H38ZM3.47,24.54H34.55V3.47H3.47Z"/><polygon class="cls-1" points="19.01 20.84 0.39 3.15 3.08 0.32 19.01 15.45 34.94 0.32 37.63 3.15 19.01 20.84"/></svg>
                    </a>
                </div>

            </ul>
        </div>
    </header>
</template>

<script>
    import axios from 'axios'
    import Vue from 'vue'
    import gsap, {Power3} from 'gsap'

    export default {
        data() {
            return {
                mobileOpen: false,
                data: null
            }
        },
        created() {
            window.addEventListener('scroll', this.handleScroll);
        },
        mounted() {
            this.loadData();
        },

        methods: {
            loadData() {
                axios.get('/data/footer.json')

                    .then(response => {
                        this.data = response.data;
                        // console.log(this.data);
                        this.$sectionLoaded();
                        Vue.nextTick(() => {
                            this.$refs.mobileMenu.addEventListener('touchmove', (e) => {
                                e.preventDefault();
                            }, {
                                passive: false
                            });
                        });
                        // Vue.nextTick(() => {

                        //     this.initTimeline();
                        //     this.initSlider();
                        //     setTimeout(() => {
                        //         // in mixin
                        //         this.tl.play();
                        //     }, 600);
                        // });
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            handleScroll(event) {
                // don't allow header to collapse on homepage
                if (this.$body.hasClass('is-home') && window.innerWidth >= 768) {
                    this.$refs.header.classList.remove('scrolled');
                    return;
                }

                if (window.scrollY > 0) {
                    this.$refs.header.classList.add('scrolled');
                } else {
                    this.$refs.header.classList.remove('scrolled');
                }
            },
        },
        watch: {
            $route(to, from) {
                this.mobileOpen = false;
            },
            mobileOpen: function(isOpen) {
                if (isOpen) {
                    let buttons = this.$refs.mobileMenu.querySelectorAll('a');
                    gsap.fromTo(buttons, 0.45, {
                        y: 200,
                        force3D: true,
                        opacity: 0
                    }, {
                        y: 0,
                        ease: Power3.easeOut,
                        opacity: 1,
                        delay: 0.05
                    }, 0.02);
                }

            }

        },
    }
</script>
