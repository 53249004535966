import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [{
        path: '/',
        name: 'home',
        meta: {
            section: true,
            parent: 'home'
        },
        component: function () {
            return import( /* webpackChunkName: "home" */ './views/Home.vue')
        },
        children: [
            {
                name: 'videoModal',
                path: 'video/:mediaId',
                parent: 'home',
                component: function () {
                    return import( /* webpackChunkName: "mediaModal" */ './modals/MediaModal.vue')
                },
                meta: {
                    section: false,
                    parent: 'home'
                },
            },

            // Ryan: is this the correct place to put the age gate?
            {
                name: 'ageGate',
                path: '/age-gate',
                parent: 'home',
                component: function () {
                    return import( /* webpackChunkName: "AgeGate" */ './modals/AgeGate.vue')
                },
                meta: {
                    section: false,
                    parent: 'home'
                },
            }

        ]
    },
    {
        path: '/who-we-are',
        name: 'who-we-are',
        meta: {
            section: true,
            parent: 'who-we-are'
        },
        component: function () {
            return import( /* webpackChunkName: "who-we-are" */ './views/WhoWeAre.vue')
        }
    },
    {
        path: '/studio-life',
        name: 'studio-life',
        meta: {
            section: true,
            parent: 'out-studio'
        },
        component: function () {
            return import( /* webpackChunkName: "studio-life" */ './views/StudioLife.vue')
        }
    },
    {
        path: '/careers',
        //name: 'careers',
        meta: {
            section: true,
            parent: 'careers'
        },
        component: function () {
            return import( /* webpackChunkName: "careers" */ './views/Careers.vue')
        },
        children: [
            {
                path: '',
                name: 'careers-landing',
                component: function () {
                    return import( /* webpackChunkName: "careers-landing" */ './views/CareersLanding.vue')
                },
                meta: {
                    section: false,
                    parent: 'careers'
                },
            },
            {
                path: ':category',
                name: 'careers-category',
                component: function () {
                    return import( /* webpackChunkName: "careers-category" */ './views/CareersCategory.vue')
                },
                meta: {
                    section: false,
                    parent: 'careers'
                },
            },
            {
                path: ':category/:jobId',
                name: 'careers-detail',
                component: function () {
                    return import( /* webpackChunkName: "careers-detail" */ './views/CareersDetail.vue')
                },
                children: [
                    {
                        name: 'careersDetailsMediaModal',
                        path: 'media/:mediaId',
                        parent: 'careers',
                        component: function () {
                            return import( /* webpackChunkName: "mediaModal" */ './modals/MediaModal.vue')
                        },
                        meta: {
                            section: false,
                            parent: 'careers'
                        },
                    },
                ],
                meta: {
                    section: false,
                    parent: 'careers'
                },
            }
        ]
    },
    {
        path: '/stories',
        // name: 'stories',
        meta: {
            section: true,
            parent: 'stories'
        },
        component: function () {
            return import( /* webpackChunkName: "stories" */ './views/Stories.vue')
        },
        children: [
            {
                path: '',
                name: 'stories-landing',
                component: function () {
                    return import( /* webpackChunkName: "stories-landing" */ './views/StoriesLanding.vue')
                },
                meta: {
                    section: false,
                    parent: 'stories'
                },
            },
            {
                path: ':id',
                name: 'stories-detail',
                component: function () {
                    return import( /* webpackChunkName: "stories-detail" */ './views/StoriesDetail.vue')
                },
                meta: {
                    section: false,
                    parent: 'stories'
                },
                children: [
                    {
                        name: 'storiesMediaModal',
                        path: 'media/:mediaId',
                        parent: 'stories',
                        component: function () {
                            return import( /* webpackChunkName: "mediaModal" */ './modals/MediaModal.vue')
                        },
                        meta: {
                            section: false,
                            parent: 'stories'
                        },
                    },
                ]
            }
        ]
    },
    {
        path: '/fandom',
        name: 'fandom',
        meta: {
            section: true,
            parent: 'fandom'
        },
        component: function () {
            return import( /* webpackChunkName: "fandom" */ './views/Fandom.vue')
        },
        children: [
            {
                path: ':mediaId',
                parent: 'fandom',
                component: function () {
                    return import( /* webpackChunkName: "mediaModal" */ './modals/MediaModal.vue')
                },
                meta: {
                    section: false,
                    parent: 'fandom'
                },
            }
        ]
    },
    {
        path: '/connect',
        name: 'connect',
        meta: {
            section: true,
            parent: 'connect'
        },
        component: function () {
            return import( /* webpackChunkName: "connect" */ './views/Connect.vue')
        }
    },
    {
        path: '*',
        name: 'not-found',
        component: function () {
            return import( /* webpackChunkName: "not-found" */ './views/404.vue')
        },
        meta: {
            isModal: false
        }
    }
    ]
})